/* RESET */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  color: #333333;
  font-family: "Source Sans Pro", "Roboto", Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.125;
}

html, body {
  width: 100%;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.5;
}

a {
  color: #0d63fd;
  text-decoration: underline;
}

img {
  display: block;
  max-width: 100%;
}

/* LAYOUT */
body {
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.container {
  width: 100%;
  margin: auto;
  padding: 0 15px;
}

@media (min-width: 1200px) {
  .container {
    width: 1140px;
  }
}
